@import '../../styles//spacings.scss';
@import '../../styles//variables.scss';

.Header-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  height: $header-height;
  padding: 16px;
  gap: 16px;
  background-color: var(--kargo-white);
  z-index: 2;
  box-shadow: 0px 4px 8px #78787850;
  transition: all 0.3s;
  .Header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 16px;
    @media screen and (min-width: $view-desktop) {
      @include maxWidth();
      flex-direction: row;
      padding: 0 16px;
    }
    .Header-image {
      width: 100px;
      cursor: pointer;
      @media screen and (min-width: $view-tablet) {
        width: 150px;
      }
    }
    .Header-links {
      display: none;
      @media screen and (min-width: $view-desktop) {
        display: flex;
        gap: 24px;
        font-size: 18px;
      }
    }
  }
}
.Header-dark { 
  color: var(--kargo-white);
  background-color: var(--kargo-black);
  box-shadow: 0px 4px 8px #36363650;

}
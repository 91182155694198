@import '../../styles/spacings.scss';
@import '../../styles/variables.scss';

.Contact-container {
  display: grid;
  width: 100%;
  gap: 32px;
  padding-top: 100px;
  .Contact-content {
    @media screen and (min-width: $view-desktop) {
      max-width: 50%;
    }
  }
  .Contact-form {

  }
}
@import './colors.scss';
@import './variables.scss';

.link {
  @include link();
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobile-only {
  @media screen and (min-width: $view-desktop) {
    display: none;
  }
}

.desktop-only {
  display: none;
  @media screen and (min-width: $view-desktop) {
    display: inherit;
  }
}

.position-sticky-top {
  @media screen and (min-width: $view-tablet) {
    position: sticky;
    top: 0;
    margin-bottom: var(--footer-height-mobile);
  }
  @media screen and (min-width: $view-desktop) {
    margin-bottom: var(--footer-height-desktop);
  }
}

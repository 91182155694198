@import '../../styles/spacings.scss';
@import '../../styles/variables.scss';

@keyframes slide-in-from-bottom {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

.Services-animation-1 {
  animation: 0.6s slide-in-from-bottom ease-out;
}
.Services-card-animation-0 {
  animation: 1.2s slide-in-from-bottom ease-out;
}
.Services-card-animation-1 {
  animation: 1.4s slide-in-from-bottom ease-out;
}
.Services-card-animation-2 {
  animation: 1.6s slide-in-from-bottom ease-out;
}
.Services-card-animation-3 {
  animation: 1.8s slide-in-from-bottom ease-out;
}

.Services-container {
  @include pageContainer();
  position: relative;
  margin-bottom: inherit;
  background-color: var(--kargo-white);
  opacity: 0;
  transition: all 1s;
  .Services-content {
    @include maxWidth();
    gap: 48px;
    .Services-title {
      @media screen and (min-width: $view-desktop) {
          max-width: 50%;
      }
    }
  }
  .Services-cards {
    display: grid;
    gap: 24px;
    @media screen and (min-width: $view-desktop) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

.Services-container-visible {
  opacity: 1;
}

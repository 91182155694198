@import '../../styles/spacings.scss';
@import '../../styles/variables.scss';

.Pricing-container {
  @include pageContainer();
  background-color: var(--kargo-black);
  @media screen and (min-width: $view-desktop) {
    max-height: 100vh;
  }
  .Pricing-content {
    @include maxWidth();
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 48px;
    @media screen and (min-width: $view-desktop) {
      gap: 60px;
    }
    .Pricing-title {
      @media screen and (min-width: $view-desktop) {
        max-width: 800px;
      }
    }
    .Pricing-prices {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 32px;
      .Pricing-price {
        width: 100%;
        object-fit: contain;
        @media screen and (min-width: $view-desktop) {
          width: 70%;
        }
      }
    }
  }
}

@mixin spacings() {
  --max-width-tablet: 500px;
  --max-width-desktop: 1300px;
  --footer-height-mobile: 148px;
  --footer-height-desktop: 128px;
}

@mixin pageContainer() {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 120px 16px 100px;
  min-height: 100vh;
  gap: 24px;
  @media screen and (min-width:$view-desktop) {
    padding: 140px 16px 100px;
  }
}

@mixin maxWidth() {
  display: flex;
  flex-direction: column;
  width: 100%;
  @media screen and (min-width:$view-tablet) {
    max-width: var(--max-width-tablet);
  }
  @media screen and (min-width:$view-desktop) {
    max-width: var(--max-width-desktop);
  }
}
@import '../../styles/spacings.scss';
@import '../../styles/variables.scss';

.Zones-container {
  @include pageContainer();
  background-color: var(--kargo-black);
  padding-bottom: 0;
  @media screen and (min-width: $view-tablet) {
    padding-bottom: auto;
  }
  @media screen and (min-width: $view-desktop) {
    max-height: 100vh;
  }
  .Zones-content {
    @include maxWidth();
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 48px;
    @media screen and (min-width: $view-desktop) {
      gap: 60px;
    }
  }
  .Zones-image {
    width: 100%;
    object-fit: contain;
    @media screen and (min-width: $view-desktop) {
      width: 50%;
    }
  }
}